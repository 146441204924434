<template>
  <nuxt-link :to="path" class="taxon-thumb">
    <div class="taxon-thumb-image-wrap">
      <img
        class="taxon-thumb-image"
        :src="src || ''"
        :alt="altText || name"
        role="presentation"
        loading="lazy"
      />
    </div>
    <div class="taxon-thumb-name">{{ name }}</div>
  </nuxt-link>
</template>

<script>
  export default {
    props: {
      href: {
        type: String,
        required: true,
      },
      src: {
        type: String,
        required: true,
      },
      altText: {
        type: String,
        default: "",
      },
      name: {
        type: String,
        required: true,
      },
    },
    computed: {
      path() {
        return this.href?.startsWith("/") ? this.href : `/${this.href}`
      },
    },
  }
</script>

<style lang="scss" scoped>
  .taxon-thumb {
    display: block;
    color: inherit;
    text-decoration: none;
  }
  .taxon-thumb-image-wrap {
    position: relative;
    width: 100%;
    padding-bottom: 100%;
    margin-bottom: $base-spacing * 3;
  }
  .taxon-thumb-image {
    position: absolute;
    right: 0;
    bottom: 0;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: block;
    object-fit: cover;
    border-radius: 4px;
    box-shadow: $shadow;

    transition:
      transform 120ms ease-in-out,
      box-shadow 120ms ease-in-out;
    transform-origin: 50% 50%;
    will-change: transform, box-shadow;
    &:hover {
      transform: scale(1.05) translate3d(0, 0, 0) perspective(1px);
      box-shadow: $hover-shadow;
    }
  }
  .taxon-thumb-name {
    max-width: 100%;
    white-space: pre-wrap;
    @apply h6;
    @include viewport("lg") {
      @apply h5;
    }
  }
</style>
